import React, { useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Vrindavan from "./Vrindavan";
import Menu from "./Menu";
import Video from "./Videos/Video";
import { NoPage } from "./NoPage";
import { UserInsert } from "../admin/user/UserInsert";
import { UserUpdate } from "../admin/user/UserUpdate";
import { UserFetchForm } from "../admin/user/UserFetchForm";
import { BookingInsertForm } from "../admin/bookings/Bookings";
import { BookingUpdateForm } from "../admin/bookings/UpdateBookings";
import { BookingUserFetchForm } from "../admin/bookings/BookingUserFetchForm";
import { RoomInsert } from "../admin/room/RoomInsert";
import { RoomUpdate } from "../admin/room/RoomUpdate";
import { RoomFetchForm } from "../admin/room/RoomFetchForm";
import { VideoGalleryFetch } from "../admin/videoGallery/VideoGalleryFetch";
import { VideoGalleryUpdate } from "../admin/videoGallery/VideoGalleryUpdate";
import { VideoGalleryInsert } from "../admin/videoGallery/VideoGalleryInsert";
import { AddCategory, AddCategoty } from "../admin/category/AddCategoty";
import { UpdateCategory } from "../admin/category/UpdateCategory";
import { FectchCategory } from "../admin/category/FectchCategory";
import { ImageFetch } from "../admin/image/ImageFetch";
import { ImageUpdate } from "../admin/image/ImageUpdate";
import { ImageInsert } from "../admin/image/ImageInsert";
import { Dashbord } from "../admin/Dashbord";
import { KathaInsert } from "../admin/katha/KathaInsert";
import { KathaUpdate } from "../admin/katha/KathaUpdate";
import { KathaFetchForm } from "../admin/katha/KathaFetchForm";
import Gallary from "./Gallary";
import MainGallary from "./MainGallary";
import SpecialDate from "./SpecialDate";
import MainViodeGallary from "./MainVideoGallary";
import { BookkathaContact } from "./BookkathaContact";
import { ContactUs } from "./ContactUs";
import About from "./About";
import { RoomBooking } from "./RoomBooking";

const Main = () => {
  const [albuminfo, setAlbumsInfo] = useState();

  return (
    <>
      <Router>
        <Menu />
        <Routes>
          <Route path="/admin/user">
            <Route path="insert" element={<UserInsert />} />
            <Route path="update" element={<UserUpdate />} />
            <Route path="show" element={<UserFetchForm />} />
          </Route>
          <Route path="/admin/katha">
            <Route path="insert" element={<KathaInsert />} />
            <Route path="update" element={<KathaUpdate />} />
            <Route path="show" element={<KathaFetchForm />} />
          </Route>
          <Route path="/admin">
            <Route path="" element={<Dashbord />} />
          </Route>
          <Route path="/admin/book">
            <Route path="insert" element={<BookingInsertForm />} />
            <Route path="update" element={<BookingUpdateForm />} />
            <Route path="show" element={<BookingUserFetchForm />} />
          </Route>
          <Route path="/admin/room">
            <Route path="insert" element={<RoomInsert />} />
            <Route path="update" element={<RoomUpdate />} />
            <Route path="show" element={<RoomFetchForm />} />
          </Route>
          <Route path="/admin/video">
            <Route path="insert" element={<VideoGalleryInsert />} />
            <Route path="update" element={<VideoGalleryUpdate />} />
            <Route path="show" element={<VideoGalleryFetch />} />
          </Route>
          <Route path="/admin/image">
            <Route path="insert" element={<ImageInsert />} />
            <Route path="update" element={<ImageUpdate />} />
            <Route path="show" element={<ImageFetch />} />
          </Route>
          <Route path="/admin/category">
            <Route path="insert" element={<AddCategory />} />
            <Route path="update" element={<UpdateCategory />} />
            <Route path="show" element={<FectchCategory />} />
          </Route>
          <Route path="/" element={<Menu />}>
            <Route index element={<Vrindavan />} />
            <Route path="/videos" element={<MainViodeGallary />} />
            <Route  path='/gallary' element={<MainGallary/>} />
            <Route  path="/specialdate/:date" element={<SpecialDate />} />
            <Route  path="/kathabook" element={<BookkathaContact />} />
            <Route  path="/contact" element={<ContactUs />} />
            <Route  path="/about" element={<About />} />
            <Route  path="/room" element={<RoomBooking />} />

            <Route path="*" element={<NoPage />} />
          </Route>
          {/* <Route exact path='/' element={<Vrindavan />} />
                    <Route  path='/BookRoom' element={<BookRoom />} />
                    <Route  path='/BookKtha' element={<BookKtha />} />
                    {/* <Route  path='/Videos' element={<YouTubeVideos/>}/> */}
          {/* <Route  path='/Video' element={<Video />} />
                    <Route  path='/maingallary' element={<MainGallary albuminfo={albuminfo} setAlbumsInfo={setAlbumsInfo} />} />
                    <Route  path='/albums' element={<Albums albuminfo={albuminfo} setAlbumsInfo={setAlbumsInfo} />} />
                    <Route  path='/Gaushala' element={<Gaushala />} />
                    <Route  path='/About' element={<About />} />
                    <Route  path='/Donate' element={<Donate />} />
                    <Route  path="/specialdate/:date" element={<SpecialDate />} /> */}
          {/* ////////////AdminPanel//////////// */}
          {/* <Route  path="/uploadvideo" element={<UploadVideos />} />
                    <Route  path="/uploadimage" element={<UploadImage />} />
                    <Route  path="/login" element={<Login />} /> */}
        </Routes>
      </Router>
    </>
  );
};

export default Main;
