import React, {  useState } from "react";
import "../../assets/css/style.css";
import { useNavigate } from "react-router-dom";
import { MdCall } from "react-icons/md";
import { MdWhatsapp } from "react-icons/md";
import CalendarReact from "react-calendar";

export const MainPageRoomBookings = () => {

  const navigate=useNavigate()
      const goToGallery=()=>
      {
        navigate("/gallary")
      }


 


  return (
    <>
      
      <div className="bg-right">
        <div>
          <div className="Calendarcontainer" id="BookKatha" data-aos="fade-up">
            <h1 className="Headingh1">Room Booking</h1>
            <div className="row innercal">
              <div className="col-lg-4 col-sm-12 my-5">
                <ul className="list-group list-group-flush rounded room-booking">
                  <li className="list-group-item text-center">
                    <strong
                      style={{
                        color: "#E1AC5B",
                        fontSize: "40px",
                        fontFamily: " Italianno, cursive",
                        marginBottom: "0",
                        paddingBottom: "0",
                      }}
                    >
                      Welcome to Shreya Ashram
                    </strong>

                    <p
                      style={{
                        color: "#ff1212",
                        fontSize: "30px",
                        fontFamily: " Italianno, cursive",
                        textAlign: "center",
                        marginBottom: "0",
                      }}
                    >
                      Free Room Booking Inquiry
                    </p>

                    <hr
                      style={{
                        padding: "0",
                        marginTop: "0",
                        background: "yellow",
                        height: "2px",
                      }}
                    />
                  </li>
                  <li className="list-group-item text-center">
                    <p
                      style={{
                        color: "#ff0E0E",
                        fontSize: "30px",
                        paddingRight: "5px",
                        fontFamily: " Italianno, cursive",
                        letterSpacing: "2px",
                        textAlign: "center",
                        lineHeight: "1",
                        marginTop: "0",
                      }}
                    >
                      If you're interested in booking a free room at Ashram,
                      please contact us using the numbers below:
                    </p>
                  </li>

                  <li className="list-group-item text-center">
                    <strong
                      style={{
                        color: "#ff0E0E",
                        fontSize: "20px",
                        paddingRight: "5px",
                      }}
                    >
                      <MdCall
                        style={{
                          color: "lightgreen",
                          fontSize: "25px",
                          margingRight: "15px",
                        }}
                      />
                      <MdWhatsapp
                        style={{
                          color: "lightgreen",
                          fontSize: "25px",
                          margingRight: "15px",
                        }}
                      />
                      <strong
                        style={{
                          color: "#E1AC5B",
                          fontSize: "25px",
                          fontFamily: "Italianno, cursive",
                          letterSpacing: "2px",
                        }}
                      >
                        : +91 9897619231/+91 7983887273
                      </strong>
                    </strong>
                  </li>

                  <li className="list-group-item text-center">
                    <p
                      style={{
                        color: "#ff0E0E",
                        fontSize: "17px",
                        paddingRight: "5px",
                        textAlign: "center",
                      }}
                    >
                      Alternatively, you can send us a message directly through{" "}
                      <MdWhatsapp
                        style={{
                          color: "lightgreen",
                          fontSize: "20px",
                          margingRight: "15px",
                        }}
                      />
                      WhatsApp or{" "}
                      <MdCall
                        style={{
                          color: "lightgreen",
                          fontSize: "25px",
                          margingRight: "15px",
                        }}
                      />
                      Call.
                    </p>
                  </li>

                  <hr
                    style={{
                      margin: "0 18%",
                      padding: "0",
                      background: "yellow",
                      height: "2px",
                    }}
                  />
                </ul>
              </div>
              <div className="col-lg-4 col-sm-12 detailsBox my-5">
                <div className="calenderBox detailsBox">
                <CalendarReact/>
                </div>
              </div>
              <div className="col-lg-4 col-sm-12 detailsBox my-5">
                <ul className="list-group list-group-flush rounded room-booking">
                  <li className="list-group-item text-center">
                    <strong
                      style={{
                        color: "#E1AC5B",
                        fontSize: "40px",
                        fontFamily: " Italianno, cursive",
                        marginBottom: "0",
                        paddingBottom: "0",
                      }}
                    >
                      Rooms Photos
                    </strong>

                    <hr
                      style={{
                        padding: "0",
                        marginTop: "0",
                        background: "yellow",
                        height: "2px",
                      }}
                    />
                  </li>
                  <li className="list-group-item text-center">
                    <p
                      style={{
                        color: "#ff0E0E",
                        fontSize: "22px",
                        paddingRight: "5px",
                        fontFamily: " Italianno, cursive",
                        letterSpacing: "2px",
                        textAlign: "center",
                        lineHeight: "1",
                        marginTop: "0",
                      }}
                    >
                      <span
                        style={{
                          color: "#E1AC5B",
                          fontSize: "27px",
                          fontFamily: " Italianno, cursive",
                        }}
                      >
                        Shreyashrey Ashram
                      </span>{" "}
                      is a peaceful place surrounded by nature. We have daily
                      prayers dedicated to
                      <span
                        style={{
                          color: "#E1AC5B",
                          fontSize: "27px",
                          fontFamily: " Italianno, cursive",
                        }}
                      >
                        {" "}
                        Thakur Ji
                      </span>{" "}
                      in the morning and evening. We also serve pure and simple
                      meals, creating a serene environment for your mind and
                      soul.
                    </p>
                   
                      <img
                        src={require("../../assets/images/buttons/roombtn.png")}
                        height={60}
                        onClick={goToGallery}
                      />
                    
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
