import { MainPageLocation } from "./indexPage/MainPageLocation"
import { MainPageRoomBookings } from "./indexPage/MainPageRoomBookings"

export const RoomBooking=()=>
{
    return(<>
    
    <MainPageRoomBookings/>
    <div className="row-full bg-left">
    <MainPageLocation/>
    </div>
    
    </>)
}